<template>
  <div v-if="$root.$data.pageSnippets" v-html="$root.$data.pageSnippets[name]">
  </div>
</template>

<script>
export default {
  name: 'PageSnippet',
  data () {
    return {
      contents: ''
    }
  },
  props: {
    name: String
  },
}
</script>

<style lang="scss" scoped>

</style>
